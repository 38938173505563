import React, { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Td from "../Components/Td";
import getLeaveType from "../../../utilities/getLeaveType";
import getStatus from "../../../utilities/getStatus";
import changeDateFormat from "../../../utilities/changeDateFormat";
import RowFilter from "../../../utilities/RowFilter";
import emailIcon from "../../../assets/images/emailIcon.png";
import emptyIcon from "../../../assets/icons/empty-set.png"
import telIcon from "../../../assets/images/telIcon.png";
import setTextForm from "../../../utilities/setTextForm";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {
  approveLeaveRequest,
  acceptNegotiation,
  rejectNegotiation,
  deleteLeave,
} from "../../../slices/leave";

import {
  approveAuthorisation,
  deleteAuthorisation,
} from "../../../slices/authorisation";
import { useDispatch, useSelector } from "react-redux";
import CollapseComponent from "./Collapse";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { closeModal, openModal } from "../../../slices/modals";
import deleteIcon from "../../../assets/icons/rubbish.png";
import editIcon from "../../../assets/icons/Edit.png";
import Avatar from "@mui/material/Avatar";
import { deleteLeaveType } from "../../../slices/leavetype";
import Tooltip from "@mui/material/Tooltip";
import getAccess from "../../../utilities/getAccess";
import pluralHandler from "../../../utilities/pluralHandler";
import { getFormattedDate } from "../../../utilities/getFormattedDate";
import { changeOneDateFormat } from "../../../utilities/changeOneDateFormat";

const Row = ({
  row,
  tableName,
  isAction,
  allowed,
  sortBy,
  getLeaveStatus,
  isCollapsed,
  isMySpace,
  isAuthorisation,
  setNotice,
  leaveID,
  page,
}) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    page && setOpen(false);
  }, [page]);
  let authorisationDetails = true;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.fetchMe);

  const isTeamLead = user.role?.code === "TEAM LEAD";
  const isRH = user.role?.code === "RH";

  const filteredRow = RowFilter(
    allowed,
    tableName === "leaves" ?
      { ...row, balance: row?.user?.balanceLeft?.toFixed(2) }
      :
      tableName === "authorisations" ?
      { ...row, authorisationBalanceLeft: row?.user?.authorisationBalanceLeft?.toFixed(2) }
      : row,
    tableName,
    sortBy
  );
  let leaveDate = [];

  const approveRequestHandler = () => {
    !isAuthorisation
      ? dispatch(approveLeaveRequest(row?._id)).then(() => {
          if (row.user.balanceLeft < row?.leaveLength) {
            setNotice({
              text: `User will be negative with ${Math.abs(
                row.user.balanceLeft - row?.leaveLength
              ).toFixed(2)}`,
              open: true,
              state: "error",
            });
          }
        })
      : dispatch(approveAuthorisation(row?._id)).then((res) => {
          if (res.error) {
            setNotice({
              text: res.error.message,
              open: true,
              state: "error",
            });
          }
        });

    getLeaveStatus(
      isRH
        ? { id: 2, code: ["approved"], name: "Approved" }
        : isTeamLead
        ? {
            id: 1,
            code: ["waiting-hr-approval"],
            name: "Waiting for HR approval",
          }
        : {}
    );
  };

  const negotiateRequestHandler = () => {
    dispatch(
      openModal("ApplyForNegotiationModal", {
        row: row,
        getLeaveStatus: getLeaveStatus,
      })
    );
  };

  const acceptNegotiationHandler = () => {
    dispatch(acceptNegotiation(row?._id));
    getLeaveStatus({
      id: 0,
      code: ["waiting-tech-approval", "waiting-hr-approval"],
      name: "Waiting for approval",
    });
  };

  const rejectNegotiationHandler = () => {
    dispatch(rejectNegotiation(row?._id));
    getLeaveStatus({ id: 3, code: ["rejected"], name: "Rejected" });
  };

  const deleteLeaveHandler = () => {
    isAuthorisation
      ? dispatch(deleteAuthorisation(row?._id))
      : dispatch(deleteLeave(row?._id));
    dispatch(closeModal("confirm-delete-modal"));
    setNotice({
      text: isAuthorisation
        ? "Authorisation  is successfully deleted!"
        : "Leave  is successfully deleted!",
      open: true,
      state: "success",
    });
  };

  const deleteLeaveTypeHandler = () => {
    dispatch(deleteLeaveType(row?._id));
    dispatch(closeModal("confirm-delete-modal"));
    setNotice({
      text: "Leave type is successfully deleted!",
      open: true,
      state: "success",
    });
  };
  return (
    <React.Fragment>
      <TableRow
        className={row._id === leaveID ? "selected-leave" : ""}
        sx={{
          "& > *": {},
        }}
      >
        {Object.entries(filteredRow).map((el, key) => (
          
          <React.Fragment key={key}>
            <TableCell
              className={tableName === "leave-types" ? "leave-types-table" : ""}
              onClick={() =>
                row?.status?.code === "rejected"
                  ? dispatch(
                      openModal("reject-leave-request-modal", {
                        rejectAuthDetails:
                          isAuthorisation || tableName === "authorisations"
                            ? true
                            : false,
                        rejectDetails:
                          isAuthorisation || tableName === "authorisations"
                            ? false
                            : true,
                        getLeaveStatus: getLeaveStatus,
                        id: row?._id,
                        row: row,
                      })
                    )
                  : tableName === "authorisations"
                  ? dispatch(
                      openModal("ApplyForAuthorisationModalDetails", {
                        ...row,
                        authorisationDetails,
                      })
                    )
                  : tableName === "leave-types" &&
                    getAccess(
                      user?.permissions,
                      user?.permissionGroups?.[0]?.permissions,
                      "LEAVE TYPE",
                      "VIEW"
                    )
                  ? dispatch(openModal("UpdateLeaveSettingsModal", row))
                  : tableName === "leaves"
                  ? row?.status?.code === "waiting-employee-negotiate"
                    ? dispatch(openModal("ApplyForNegotiationModal", row))
                    : getAccess(
                        user?.permissions,
                        user?.permissionGroups?.[0]?.permissions,
                        "LEAVE",
                        "LIST"
                      ) ||
                      getAccess(
                        user?.permissions,
                        user?.permissionGroups?.[0]?.permissions,
                        "LEAVE",
                        "CREATE"
                      )
                    ? dispatch(openModal("ApplyForLeaveDetailsModal", row))
                    : ""
                  : tableName === "users" &&
                    getAccess(
                      user?.permissions,
                      user?.permissionGroups?.[0]?.permissions,
                      "USER",
                      "VIEW"
                    )
                  ? dispatch(openModal("EmpDetModal", { id: row?._id }))
                  : ""
              }
            >
              {
                el[1] == null ? (<Td  icon={emptyIcon}  />)
                    :  

                  el[1][1] == null ? ( <Td icon={emptyIcon}  /> ) :

                     el[1][0] === "status" ? 
                      (tableName === "users" ? 
                        ( <Td text={setTextForm(el[1][1].name)} className={getStatus(el[1][1].name).className}/> )
                        : 
                        (  <Td icon={getStatus(el[1][1].label).icon}
                               text={ 
                                setTextForm(
                                  row?.dateRejectRH ? "Rejected by RH" 
                                  : row?.dateRejectTL ? "Rejected by TL": el[1][1].label )
                           } 
                               className={getStatus(el[1][1].label).className}
                        />
                        )
                      ) : 
                     el[1][0] === "authorisationTime" && tableName === "authorisations" ?
                      ( <Td text={  "   From   " +  el[1][1]?.startTime + "   To   " +el[1][1]?.endTime }/> ) :
                     el[1][0] === "authorisationDate" && tableName === "authorisations" ? 
                      (<Td text={changeOneDateFormat(el[1][1].date)} />) :
                     el[1][0] === "leaveDate" ? 
                     ( <Td text={changeDateFormat(  el[1][1].dateStart,  el[1][1].dateEnd )} />
                ) : 

                el[0] === "dateStart" ? 
                  ( leaveDate.push(el[1]) && <Td style={{ display: "none" }} />) :
                el[0] === "dateEnd" ?
                 (leaveDate.push(el[1]) && (<Td text={changeDateFormat(leaveDate[0], leaveDate[1])} />)) :
                  
              
                  el[1][0] === "userInfo" ? ( 
                      <>
                        <Td icon={emailIcon} text={el[1][1].email} />
                        <Td icon={telIcon} text={`+216 ${el[1][1].phone}`} />
                      </> ) : 
                  el[1][0] === "user" ? 
                      ( <Td icon={el[1][1].img ? el[1][1].img : <Avatar />}
                      text={el[1][1].fullName}  description={el[1][1].jobTitle}
                      className="user-name"  imgSize="user-img"
                    />
                      ) : 
                  el[1][0] === "type" ? (
                    <Td icon={getLeaveType(el[1][1]?.name).icon}
                      className={getLeaveType(el[1][1]?.name).className}
                      text={`${el[1][1]?.name} leave`}
                    />) :
                     tableName === "leave-types" ? (
                  el[1][0] === "img" ? (
                    <Td icon={el[1][1]} imgSize="leave-type-img" costumWidth />
                  ) : el[1][0] === "name" ? (
                    <Td text={el[1][1]} />
                  ) : el[1][0] === "preview" ? (
                    <Td
                      style={{ color: el[1][1].color }}
                      text={el[1][1].name}
                      icon={el[1][1].img ? el[1][1].img : <Avatar />}
                      imgSize="leave-type-img"
                    />
                  ) : (
                    ""
                  )
                     ) :
                  el[1][0] === "department" ? (
                    <Td text={`${el[1][1]?.name} Department`} />
                    ) :
                  el[1][0] === "authorisationLength" ||  el[1][0] === "authorisationBalanceLeft" ? ( 
                    <>
                      <Td text={`${ el[1][1] + " " + pluralHandler(el[1][1], "Hour") }`}/>
                    </>
                    ) : 
                  el[1][0] === "balance" || el[1][0] === "leaveLength" ? (
                  <>
                    <Td
                      text={`${
                        el[1][1] + " " + pluralHandler(el[1][1], "Day")
                      }`}
                    />
                  </>
                  ) :
                  el[1][0] === "dateApprovedTL" || el[1][0] === "dateApprovedRH" ||
                  el[1][0] === "dateRejectTL" ||el[1][0] === "dateRejectRH" || el[1][0] === "dateRejectNegotiation" ? 
                   ( <Td text={getFormattedDate(new Date(el[1][1]), "-")} /> )  :
                   (  <Td text={el[1][1]} /> )
              }
            </TableCell>
            {isAction && key === 2 && tableName === "leave-types" && (
              <TableCell>
                <div className="table-actions leavetypes-action">
                  {getAccess(
                    user?.permissions,
                    user?.permissionGroups?.[0]?.permissions,
                    "LEAVE TYPE",
                    "VIEW"
                  ) && (
                    <div
                      className="edit-action"
                      onClick={() =>
                        dispatch(openModal("UpdateLeaveSettingsModal", row))
                      }
                    >
                      <img src={editIcon} alt="edit-icon" />
                    </div>
                  )}
                  {getAccess(
                    user?.permissions,
                    user?.permissionGroups?.[0]?.permissions,
                    "LEAVE TYPE",
                    "DELETE"
                  ) && (
                    <div
                      className="delete-action"
                      onClick={() =>
                        dispatch(
                          openModal("confirm-delete-modal", {
                            id: row?._id,
                            Item: "leave type",
                            deleteHandler: deleteLeaveTypeHandler,
                          })
                        )
                      }
                    >
                      <img src={deleteIcon} alt="delete-icon" />
                    </div>
                  )}
                </div>
              </TableCell>
            )}
            {isAction === true && key === 5 && (
              <TableCell>
                <div className="table-actions">
                  {getAccess(
                    user?.permissions,
                    user?.permissionGroups?.[0]?.permissions,
                    "LEAVE",
                    "DELETE"
                  ) ||
                  getAccess(
                    user?.permissions,
                    user?.permissionGroups?.[0]?.permissions,
                    "AUTHORISATIONS",
                    "DELETE"
                  ) ? (
                    <div
                      className="delete-action"
                      onClick={() =>
                        dispatch(
                          openModal("confirm-delete-modal", {
                            id: row?._id,
                            Item: isAuthorisation ? "authorisation" : "leave",
                            deleteHandler: deleteLeaveHandler,
                          })
                        )
                      }
                    >
                      <Tooltip title="Delete">
                        <img src={deleteIcon} alt="delete-icon" />
                      </Tooltip>
                    </div>
                  ) : (
                    (getAccess(
                      user?.permissions,
                      user?.permissionGroups?.[0]?.permissions,
                      "LEAVE",
                      "REJECT"
                    ) ||
                      getAccess(
                        user?.permissions,
                        user?.permissionGroups?.[0]?.permissions,
                        "LEAVES NEGOTIATION",
                        "REJECT"
                      ) ||
                      getAccess(
                        user?.permissions,
                        user?.permissionGroups?.[0]?.permissions,
                        "AUTHORISATIONS",
                        "REJECT"
                      )) && (
                      <div
                        className="reject-action"
                        onClick={() =>
                          isMySpace
                            ? rejectNegotiationHandler()
                            : dispatch(
                                openModal("reject-leave-request-modal", {
                                  isLeaveOrAuthorisation: isAuthorisation
                                    ? true
                                    : false,
                                  getLeaveStatus: getLeaveStatus,
                                  id: row?._id,
                                })
                              )
                        }
                      >
                        <Tooltip title="Reject">
                          <CloseIcon />
                        </Tooltip>
                      </div>
                    )
                  )}

                  {/* {getAccess(
                    user?.permissions,
                    user?.permissionGroups?.[0]?.permissions,
                    "LEAVES NEGOTIATION",
                    "CREATE"
                  ) &&
                    tableName !== "authorisations" && (
                      <div
                        className="pending-action"
                        onClick={negotiateRequestHandler}
                      >
                        <Tooltip title="Negotiate">
                          <AccessTimeIcon />
                        </Tooltip>
                      </div>
                    )} */}
                  {(getAccess(
                    user?.permissions,
                    user?.permissionGroups?.[0]?.permissions,
                    "LEAVE",
                    "APPROVE"
                  ) ||
                    getAccess(
                      user?.permissions,
                      user?.permissionGroups?.[0]?.permissions,
                      "LEAVES NEGOTIATION",
                      "APPROVE"
                    )) && (
                    <div
                      className="approve-action"
                      onClick={
                        isMySpace
                          ? acceptNegotiationHandler
                          : approveRequestHandler
                      }
                    >
                      <Tooltip title="Approve">
                        <DoneIcon />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </TableCell>
            )}
            {isCollapsed === true && key === 3 && (
              <TableCell>
                <div className="collapse-btn" onClick={() => setOpen(!open)}>
                  <IconButton aria-label="expand row" size="small">
                    {open ? (
                      <KeyboardArrowUpIcon className="rowActions" />
                    ) : (
                      <KeyboardArrowDownIcon className="rowActions" />
                    )}
                  </IconButton>
                </div>
              </TableCell>
            )}
          </React.Fragment>
        ))}
      </TableRow>

      {isCollapsed && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, height: 0 }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <CollapseComponent setOpen={setOpen} open={open} row={row} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default Row;
